export default function App() {
  return (
    <div className="bg-[#010040] flex flex-col text-white justify-center gap-4 items-center w-[100vw] h-[100vh] px-4">
      {/* <h1 className="logo"></h1> */}
      <img src="/alif.jpeg" alt="" width={200} />
      <p className="text-center">
        We're working hard to bring you something amazing. 
      </p>
      <p className="font-bold underline text-2xl cursor-pointer">Stay tuned!</p>
    </div>
  );
}
